$primary: #00333b;
$secondary: #22505f;
$tertiary: #156570;
$background: #fcfcfc;
$danger: #dd0000;
$success: #023020;
$warning: orange;
$info: aqua;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;

/* beautify ignore:start */
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    // 4px
    2:
      (
        $spacer * 0.5,
      ),
    // 8px
    3:
      (
        $spacer * 0.75,
      ),
    // 12px
    4: $spacer,
    // 16px
    5:
      (
        $spacer * 1.5,
      ),
    // 24px
    6:
      (
        $spacer * 2,
      ),
    // 32px
    7:
      (
        $spacer * 2.5,
      ),
    // 40px
    8:
      (
        $spacer * 3,
      ),
    // 48px
    9:
      (
        $spacer * 4,
      ),
    // 64px
    10:
      (
        $spacer * 5,
      ),
    // 80px
    11:
      (
        $spacer * 6,
      ),
    // 96px
    12:
      (
        $spacer * 8,
      ),
    // 128px
    13:
      (
        $spacer * 10,
      ),
    // 160px
    14:
      (
        $spacer * 12,
      ),
    // 192px
    15:
      (
        $spacer * 16,
      ),
    // 256px
    16:
      (
        $spacer * 25,
      ),
    // 400px
  ),
  $spacers
);
/* beautify ignore:end */

h1,
h2,
h3,
h4,
h5 {
  color: $primary;
}

h1 {
  font-size: 35px !important;
}

h2 {
  font-size: 30px !important;
}

h3 {
  font-size: 25px !important;
}

p,
a {
  font-size: 18px;
}

header {
  background-image: url("../public/Journey-Stripes.jpg");
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: left bottom;
}

@media (max-width: 600px) {
  header {
    background-size: 30%;
    background-position: left top; /* Adjust positioning for smaller screens (e.g., mobile devices) */
  }
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: block;
}

footer p,
a {
  font-size: 15px;
}

.main {
  min-height: 50vh !important;
  padding-top: 50px;
  margin-bottom: 150px;
  padding-left: 10px;
  padding-right: 10px;
}

.questionnaire-form {
  width: 800px;
}

.questionnaire-grid {
  max-width: 800px !important;
}

@media (max-width: 600px) {
  .questionnaire-form {
    width: 90%;
    max-width: 90%;
  }
}

.App-header {
  min-height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white !important;
}

.logo {
  width: 250px;
}

.styled-button {
  border: solid 2px #589199;
  font-family: "AvantGardeMedium", "system-ui", "Segoe UI", "Roboto",
    "Helvetica", "Arial", sans-serif;
  padding: 0.75em 2em;
  font-size: 1em;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #589199;
  line-height: 1;
  width: auto;
  overflow: hidden;
  display: inline-block;
  letter-spacing: 0.1em;
  position: relative;
  text-align: center;
  min-width: 12em;
  z-index: 1;
  text-decoration: none;
}

.report-print-out {
  transform: scale(1);
  transform-origin: top left;
}

.report-print-out h1,
.report-print-out h2 {
  font-size: 17px !important;
}

.report-print-out h3 {
  font-size: 11px !important;
}

.report-print-out p,
.report-print-out a {
  font-size: 12px;
}

.report-print-out div {
  font-size: 14px !important;
}

.report-print-out span {
  height: max-content !important;
}

.report-print-out th,
.report-print-out td {
  font-size: 8.6px;
  width: 128px !important;
  height: auto !important;
  padding: 5px !important;
  white-space: normal !important;
  word-break: break-word;
}

.report-print-out table {
  table-layout: fixed;
}

.report-print-out table tr {
  height: 20px !important;
  padding: 0px !important;
}

.report-print-out svg {
  height: 8px !important;
}

.report-print-out .main-svg-title {
  height: 90px !important;
}

.report-print-out button {
  visibility: hidden !important;
  font-size: 5px !important;
}

.report-print-out .MuiInputBase-root {
  height: 20px;
  font-size: 11px;
}

.report-print-out .small-column {
  width: 30px !important;
}

.report-print-out .medium-column {
  width: 40px !important;
}

.page-break-after {
  page-break-after: always;
}

.hidden-content {
  display: none;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
